import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'We\'re here for you!',
    paragraph: 'We love hearing from our customers and truly value your feedback. Send us your questions, comments or feedback so we can serv you better.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-16">
                  Quick and Easy
                  </div>
                <h3 className="mt-0 mb-12">
                  Call us
                  </h3>
                <p className="m-0">
                  (647) 503-5054 
                  </p>
              </div>
              <div className={
                // classNames(
                //   'split-item-image center-content-mobile reveal-from-bottom',
                //   imageFill && 'split-item-image-fill'
                // )
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom'
                )}
                data-reveal-container=".split-item">
                <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16">
                        <Image
                        src={require('./../../assets/images/features-split-image-01.png')}
                        alt="Features split 03"
                        width={150}
                        height={150} />
                      </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-16">
                  Guaranteed reponse
                  </div>
                <h3 className="mt-0 mb-12">
                  Email us
                  </h3>
                <p className="m-0">
                  info@yyzanalytics.com
                  </p>
              </div>
              <div className={
                // classNames(
                //   'split-item-image center-content-mobile reveal-from-bottom',
                //   imageFill && 'split-item-image-fill'
                // )
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom'
                )}
                data-reveal-container=".split-item">
                <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16">
                        <Image
                        src={require('./../../assets/images/features-split-image-02.png')}
                        alt="Features split 03"
                        width={150}
                        height={150} />
                      </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-16">
                  Snailmail
                  </div>
                <h3 className="mt-0 mb-12">
                  Mailing address
                  </h3>
                <p className="m-0">
                  YYZ Analytics<br></br>
                  136-2446 Bank Street - Suite 639<br></br>
                  Ottawa, ON<br></br>
                  K1V 1A4

                  </p>
              </div>
              <div className={
                // classNames(
                //   'split-item-image center-content-mobile reveal-from-bottom',
                //   imageFill && 'split-item-image-fill'
                // )
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom'
                )}
                data-reveal-container=".split-item">
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16">
                        <Image
                        src={require('./../../assets/images/features-split-image-03.png')}
                        alt="Features split 03"
                        width={150}
                        height={150} />
                      </div>
                    </div>
                  </div>      
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;