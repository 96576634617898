import React from 'react';
// import sections
import ServicesHero from '../components/sections/ServicesHero';
import ServicesFeaturesTiles from '../components/sections/ServicesFeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Services = () => {

  return (
    <>
      <ServicesHero className="illustration-section-01" />
      <ServicesFeaturesTiles />
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />
      <Cta split /> */}
    </>
  );
}

export default Services;