import React from 'react';
// import sections
import ContactHero from '../components/sections/ContactHero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import ContactFeaturesSplit from '../components/sections/ContactFeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Contact = () => {

  return (
    <>
      <ContactHero className="illustration-section-01" />
      {/* <FeaturesTiles /> */}
      <ContactFeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      {/* <Testimonial topDivider />
      <Cta split /> */}
    </>
  );
}

export default Contact;